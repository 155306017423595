//
// Separator
//

.separator {
  height: 0;

  // Dashed style
  &.separator-dashed {
    border-bottom: 1px dashed $border-color;
  }

  &.separator-solid {
    border-bottom: 1px solid $border-color;
  }

  &.separator-border-2 {
    border-bottom-width: 2px;
  }

  &.separator-border-3 {
    border-bottom-width: 3px;
  }

  &.separator-border-4 {
    border-bottom-width: 4px;
  }

  // Theme colors
  @each $name, $color in $theme-colors {
    &.separator-#{$name} {
      border-bottom-color: $color;
    }
  }
}

.fade-enter .fade-user {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active .fade-user {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit .fade-user {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active .fade-user {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active .fade-user,
.fade-exit-active .fade-user {
  transition: opacity 500ms, transform 500ms;
}

.purple-button {
    background-color: #D8D0FFA6;
    color: #000;
}

.purple-button:hover {
    background-color: rgba(113,84,255,0.65);
    color: #fff;
}