//
// Offcanvas
//

@mixin offcanvas-base($class, $config) {
  $width: get($config, width);
  $transition: left 0.3s ease, right 0.3s ease;

  .#{$class} {
    z-index: get($config, zindex);
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: auto;

    //backface-visibility: hidden;
    //-webkit-transform: translate3d(0, 0, 0);
    //-webkit-backface-visibility: hidden;
    //-webkit-overflow-scrolling: touch;

    width: $width;
    transition: $transition;
    left: -($width + 40px);

    &.#{$class}-on {
      transition: $transition;
      left: 0;
    }

    @include for-ie11 {
      transition: none !important;
    }
  }

  // Right Direction
  .#{$class}.#{$class}-right {
    right: -($width + 40px);
    left: auto;

    &.#{$class}-on {
      transition: $transition;
      right: 0;
      left: auto;
    }
  }

  // Overlay
  .#{$class}-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: get($config, zindex) - 1;
    animation: animation-offcanvas-fade-in 0.6s ease 1;
  }

  // Animation
  @keyframes animation-offcanvas-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@mixin offcanvas-width($class, $width) {
  .#{$class} {
    width: $width;
    left: -($width + 20px);

    &.#{$class}-on {
      left: 0;
    }
  }

  // Right Direction
  .#{$class}.#{$class}-right {
    right: -($width + 20px);
    left: auto;

    &.#{$class}-on {
      right: 0;
      left: auto;
    }
  }
}

@mixin offcanvas-theme($class, $config) {
  .#{$class} {
    @if (has($config, bg-color)) {
      background: get($config, bg-color);
    }

    @if (has($config, shadow)) {
      box-shadow: get($config, shadow);
    }
  }

  .#{$class}-overlay {
    background: get($config, overlay-bg-color);
  }
}

@mixin offcanvas-build($class, $mode, $config) {
  @if $mode == default {
    // Default Mode
    @include offcanvas-base($class, $config);
    @include offcanvas-theme($class, $config);
  }

  @if $mode == desktop {
    // Desktop Mode
    @include media-breakpoint-up(lg) {
      @include offcanvas-base($class, $config);
      @include offcanvas-theme($class, $config);
    }
  }

  @if $mode == desktop-and-tablet {
    // Desktop & Tablet Modes
    @include media-breakpoint-up(md) {
      @include offcanvas-base($class, $config);
      @include offcanvas-theme($class, $config);
    }
  }

  @if $mode == tablet {
    // Tablet Mode
    @include media-breakpoint-between(md, lg) {
      @include offcanvas-base($class, $config);
      @include offcanvas-theme($class, $config);
    }
  }

  @if $mode == tablet-and-mobile {
    // Tablet & Mobile Modes
    @include media-breakpoint-down(md) {
      @include offcanvas-base($class, $config);
      @include offcanvas-theme($class, $config);
    }
  }

  @if $mode == mobile {
    // Mobile Mode
    @include media-breakpoint-down(sm) {
      @include offcanvas-base($class, $config);
      @include offcanvas-theme($class, $config);
    }
  }

  // Responsive Width
  @media (max-width: get($config, width) + 50px) {
    .#{$class} {
      width: 100% !important;
    }
  }
}
